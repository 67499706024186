import { useFormikContext } from 'formik';
import { InputField } from '../../shared/formFields/InputField';
import { SelectField } from '../../shared/formFields/SelectField';
import { Checkbox } from '../../shared/formFields/Checkbox';
import { PropertyType } from '../../../types';
import { FieldsGroupWrapper } from './PropertyFormStyle';

const currencyOptions = [
  { label: 'грн.', value: 'UAH' },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
];

export const CharacteristicsFields = () => {
  const { values, handleChange, handleBlur, setFieldValue, setFieldTouched, touched, errors } =
    useFormikContext<PropertyType>();
  return (
    <>
      <FieldsGroupWrapper>
        <InputField
          label='Площа будівлі GBA'
          name='gba_m2'
          value={values.gba_m2 || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
        <InputField
          label='Площа будівлі GLA'
          name='gla_m2'
          value={values.gla_m2 || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
        <InputField
          label='Висота стелі. м'
          name='height'
          value={values.height || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'auto'}
        />
      </FieldsGroupWrapper>

      <FieldsGroupWrapper>
        <InputField
          label='Орендна ставка'
          name='rent'
          value={values.rent || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'150px'}
        />
        <SelectField
          name='currency'
          label='Виберіть валюту'
          value={values.currency}
          options={currencyOptions}
          handleChange={setFieldValue} //
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
          errors={errors}
          $width='100px'
        />
        <InputField
          label='ОРЕХ'
          name='opex'
          value={values.opex || ''}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          type='number'
          $width={'150px'}
        />
        <SelectField
          name='currencyOpex'
          label='Виберіть валюту'
          value={values.currency}
          options={currencyOptions}
          handleChange={setFieldValue} //
          handleBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          touched={touched}
          errors={errors}
          $width='100px'
        />
        <InputField
          label='R/U по будівлі, %'
          name='ruratio'
          value={values.ruratio}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          $width={'150px'}
        />
      </FieldsGroupWrapper>
      <Checkbox label={'Укриття'} name={'shelter'} handleChange={handleChange} value={values.shelter} />
      <Checkbox label={'Резервне живлення'} name={'generator'} handleChange={handleChange} value={values.generator} />
      <FieldsGroupWrapper>
      <InputField
          label='Енергопостачання'
          name='energySupply'
          value={values.energySupply}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
        <InputField
          label='Опалення'
          name='heating'
          value={values.heating}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
        <InputField
          label='Противопожежна система'
          name='fireprotect'
          value={values.fireprotect}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
        <InputField
          label='Система безпеки'
          name='security'
          value={values.security}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
        <InputField
          label='Система вентиляції і кондиціонування'
          name='ventilation'
          value={values.ventilation}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />

        <InputField
          label='Парковка для автомобілів'
          name='parking'
          value={values.parking}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
        <InputField
          label='Ліфтова система'
          name='lifts'
          value={values.lifts}
          handleChange={handleChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
        />
      </FieldsGroupWrapper>
    </>
  );
};
