import { useState } from 'react';
import { ImageType, ImageListType } from 'react-images-uploading';
import { Formik, Form, FormikErrors } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { OfficeType } from '../../../../types';
import { BoxButton } from '../../../shared/buttons/BoxButton';
import { InputField } from '../../../shared/formFields/InputField';
import { TextAreaField } from '../../../shared/formFields/TextAreaField';
import { FieldsGroupWrapper, SelectWrapper, SubTitle } from '../PropertyFormStyle';
import { SelectField } from '../../../shared/formFields/SelectField';
import { ModalLayout } from '../../../shared/ModalLayout';
import { officeValidationSchema } from '../validationSchema';
import {
  addImagesToBc,
  addEditOfficeToProperty,
  useImagesByPropertyIdAndOfficeId,
  deleteImage,
  uploadImage,
} from '../../../../services/propertiesService';
import { MultiImagesUploader } from '../imagesField/MultiImagesUploader';
import { ButtonGroup } from '../../../shared/buttons/ButtonGroup';
import { getImageBlobData, getImagesBlobData } from '../../../../utils/imagesBlobData';
import { ImagesView, ImageView } from '../imagesField/ImagesView';
import { constants } from '../../../../utils/constants';
import { SingleImageUploader } from '../imagesField/SingleImageUploader';

interface OfficeItemProps {
  bcId: string;
  office: OfficeType | null;
  handleCloseModal: () => void;
  mutate: any;
}

const modalOptions = {
  size: 'small',
  title: 'Add Office',
};

const defaultInitialValues: OfficeType = {
  id: uuidv4(),
  square: 0,
  name: '',
  comment: '',
  state: '',
  planning: '',
  price: 0,
  currency: 'UAH',
  images: [],
};

export const OfficeFormModal = ({ bcId, office, handleCloseModal, mutate }: OfficeItemProps) => {

  const [uploadedImages, setUploadedImages] = useState<ImageListType | []>([]);
  const [uploadedPlanningImage, setUploadedPlanningImage] = useState<ImageListType | []>([]);

  const officeId = !!office ? office.id : defaultInitialValues.id;
  const imagePath = `${bcId}/offices/${officeId}/images/`;
  const planningImageName = constants.PLANNING_IMAGE_NAME;

  const { data, mutate: officeImagesMutate } = useImagesByPropertyIdAndOfficeId(bcId, officeId);
  const images = data?.data.map((item: string) => item.split('/')[5]);

  const planningImageUrl = images?.find((img: string) => img.includes(planningImageName));
  const buttonTitle = planningImageUrl ? 'Замінити' : 'Завантажити';

  const restImages = images?.filter((img: string) => img !== planningImageUrl) || [];

  const modalOptions = {
    size: 'big',
    title: !office ? 'Додати офіс' : 'Редагувати офіс',
  };

  const handleSubmit = (values: OfficeType) => {
    console.log('values', values);
    addEditOfficeToProperty(bcId, values)
      .then((res) => {
        console.log('res', res);
        handleCloseModal();
        mutate();
      })
      .catch((e) => console.log('error', e));
  };

  const handleUploadImages = async (values: OfficeType) => {
    const imagesBlobData = getImagesBlobData(uploadedImages);

    try {
      await addImagesToBc(imagesBlobData, bcId, values.id);
      setUploadedImages([]);
      officeImagesMutate();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadPlanningImage = async () => {
    const imageBlobData = uploadedPlanningImage && getImageBlobData(uploadedPlanningImage[0]);
    const extention = imageBlobData.name && imageBlobData.name.split('.')[1];
    const fileName = planningImageName + extention;
    const payload = imageBlobData.blobData;
    console.log('fileName', fileName);

    try {
      const result = await uploadImage(bcId, fileName, payload, officeId);
      //console.log('result', result);
      setUploadedPlanningImage([]);
      officeImagesMutate();
    } catch (e) {
      console.error(e);
    }
  };


  const handleImageRemove = async (imageName: string) => {
    try {
      await deleteImage(bcId, imageName, officeId);
      officeImagesMutate();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ModalLayout closeModal={handleCloseModal} options={modalOptions}>
      <Formik
        initialValues={office || defaultInitialValues}
        onSubmit={(values) => handleSubmit(values)}
        validationSchema={officeValidationSchema}
        validateOnChange={true}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          isValid,
          handleChange,
          handleBlur,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => {
          //console.log('values', values);
          //console.log('errors', errors);
          //console.log('touched', touched);
          return (
            <Form>
              <FieldsGroupWrapper>
                <InputField
                  label='Поверх'
                  name='name'
                  value={values.name}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  $width={'40%'}
                  required
                />
                <TextAreaField
                  label='Короткий опис'
                  name='comment'
                  value={values.comment || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  $width={'55%'}
                  rows={2}
                />
              </FieldsGroupWrapper>
              <FieldsGroupWrapper>
                <SelectWrapper>
                  <SelectField
                    label='Стан приміщень'
                    name='state'
                    value={values.state}
                    options={[
                      { label: '', value: '' },
                      { label: 'без адаптації', value: 'без адаптації' },
                      { label: 'з ремонтом', value: 'з ремонтом' },
                      { label: 'з меблями', value: 'з меблями' },
                    ]}
                    handleChange={setFieldValue}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    required
                  />
                </SelectWrapper>
                <SelectWrapper>
                  <SelectField
                    label='Планування'
                    name='planning'
                    value={values.planning}
                    options={[
                      { label: '', value: '' },
                      { label: 'open space', value: 'open space' },
                      { label: 'змішане', value: 'змішане' },
                      { label: 'кабінетне', value: 'кабінетне' },
                    ]}
                    handleChange={setFieldValue}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    touched={touched}
                    errors={errors}
                    required
                  />
                </SelectWrapper>
              </FieldsGroupWrapper>
              <FieldsGroupWrapper>
                <InputField
                  label='Орендна ставка'
                  name='price'
                  value={values.price || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type='number'
                  $width={'150px'}
                />
                <SelectField
                  name='currency'
                  label='Виберіть валюту'
                  value={values.currency}
                  options={[
                    { label: 'грн.', value: 'UAH' },
                    { label: 'USD', value: 'USD' },
                    { label: 'EUR', value: 'EUR' },
                  ]}
                  handleChange={setFieldValue} //
                  handleBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                  errors={errors}
                  $width='100px'
                />
                <InputField
                  label='Площа'
                  name='square'
                  value={values.square || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                  type='number'
                  $width={'150px'}
                />
              </FieldsGroupWrapper>

              <SubTitle>Планування:</SubTitle>
              {planningImageUrl && uploadedPlanningImage.length === 0 && (
                <ImageView image={planningImageUrl} imageWidth={'100'} imagePath={imagePath} />
              )}
              <SingleImageUploader
                uploadedImage={uploadedPlanningImage}
                setUploadedImage={setUploadedPlanningImage}
                handleUploadImage={handleUploadPlanningImage}
                buttonTitle={buttonTitle}
              />

              <SubTitle>Збережені зображення:</SubTitle>
              {restImages.length > 0 && (
                <ImagesView
                  images={restImages}
                  imageWidth={'100'}
                  onImageRemove={handleImageRemove}
                  imagePath={imagePath}
                />
              )}
              <MultiImagesUploader
                uploadedImages={uploadedImages}
                setUploadedImages={setUploadedImages}
                handleUploadImages={() => handleUploadImages(values)}
              />
              <ButtonGroup $buttonGap='16px' $marginTop='32px'>
                <BoxButton
                  ariaLabel='add'
                  disabled={!dirty || !isValid}
                  onClick={() => handleSubmit(values)}
                  $padding='10px 20px'
                  type='button'
                >
                  Save
                </BoxButton>
                <BoxButton ariaLabel='add' disabled={!dirty} onClick={resetForm} $padding='10px 20px' type='button'>
                  Reset
                </BoxButton>
              </ButtonGroup>
            </Form>
          );
        }}
      </Formik>
    </ModalLayout>
  );
};
