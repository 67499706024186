import { useParams } from 'react-router-dom';
//import { mockProperty } from '../../../mockData/property';
import { usePropertyById, useOfficesByPropertyId } from '../../../services/propertiesService';
import { PropertyPageWrapper, StyledLink, PageHeader, Icon } from './PropertyPageStyle';
import { OfficesTable } from './OfficesTable';
import { useState } from 'react';
import arrow_back from '../../../assets/icons/arrow_back.svg';
import { BoxButton } from '../../shared/buttons/BoxButton';

export const PropertyPage = () => {
  //const property = mockProperty;

  const { propertyId } = useParams();
  const [selectedOffices, setSelectedOffices] = useState<string[]>([]);
  const { data: property } = usePropertyById(propertyId);
  console.log('property', property);

  const { data: offices, mutate, isLoading } = useOfficesByPropertyId(propertyId);
  return (
    <PropertyPageWrapper>
      <PageHeader>
      <StyledLink to={'/properties'}>
          <Icon src={arrow_back} /> повернутися до списку{' '}
        </StyledLink>
      </PageHeader>
      <h1>{property?.data.name}</h1>
      <div>{property?.data.shortDesc}</div>
      <div>{offices && <OfficesTable offices={offices.data} selectedOffices={selectedOffices} setSelectedOffices={setSelectedOffices}/>}</div>
      <BoxButton
        ariaLabel='download pdf'
        onClick={() => console.log('pdf')}
        $padding='5px'
        type='button'
        disabled={selectedOffices.length===0}
      >
        Завантажити PDF
      </BoxButton>

    </PropertyPageWrapper>
  );
};
