import styled, { css } from 'styled-components';

interface FieldLayoutProps {
  $width: string;
}
export const FieldLayout = styled.div<FieldLayoutProps>`
  display: flex;
  width: ${(props) => (props.$width ? props.$width : 'auto')};
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
`;
interface StyledInputProps {
  $margin?: string;
}
export const Input = styled.input<StyledInputProps>`
  box-sizing: border-box;
  font: inherit;
  border: ${(props) => props.theme.border.gray};
  border-radius: 2px;
  padding: 5px;
  margin: ${(props) => props.$margin};
  text-align: left;
  width: 100%;
  height: 45px;
  outline: none;
  transition: 0.4s;
  &::placeholder {
    color: ${(props) => props.theme.darkGreyShadow};
  }
  &:not(:disabled):hover,
  &:not(:disabled):focus,
  &:not(:disabled):active {
    border: ${(props) => props.theme.border.blue};
    box-shadow: 0 0 0 1px #2684ff;
  }
  :disabled {
    border: 1 px solid ${(props) => props.theme.lightgray};
  }
`;
export const Error = styled.span`
  color: ${(props) => props.theme.red};
  font-size: 14px;  
  display: block;
  max-width: 200px;
`;
export const SuccessMessageWrapper = styled.div`
display: flex;
color: ${(props) => props.theme.green};
justify-content: left;
margin-top: 10px;
`;
export const ErrorMessageWrapper = styled.div`
display: flex;
color: ${(props) => props.theme.red};
margin-top: 10px;
justify-content: left;
`;


export const RequiredDot = css`
  &::after {
    color: ${(props) => props.theme.warning};
    content: '*';
    padding-left: 3px;
  }
`;
export const StyledLabel = styled.label<{
  required?: boolean;
  disabled?: boolean;
  $isError?: string | boolean;
}>`
  cursor: pointer;
  display: inline-block;
  height: 15px;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
  color: ${(props) => props.theme.marineBlue};

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      color: ${props.theme.marineBlue55};
    `}
  ${(props) =>
    props.$isError &&
    css`
      color: ${props.theme.red};
    `}
  
    ${(props) => props.required && RequiredDot}
`;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  font: inherit;
  border: 1px solid ${(props) => props.theme.gray};
  border-radius: 2px;
  padding: 6px;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  outline: none;
  transition: 0.4s;
  resize: none;
  height: 100%;

  &:hover,
  &:focus,
  &:active {
    border: ${(props) => props.theme.border.blue};
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

export const StyledTabName = styled.span<{
  required?: boolean;
}>`
  ${(props) => props.required && RequiredDot}
`;

export const Icon = styled.img`
  cursor: pointer;
`;

