import useSWR from 'swr';
import { getJsonAsync, deleteJsonAsync, putJsonAsync } from './client';
//const url = '/backend/111';

const getHeadersForImg = () => {
  return {
    'Content-Type': 'image/jpeg',
  };
};

export const useProperties = () => {
  const url = '/business-centers';
  return useSWR(url, () => getJsonAsync(url));
};


export const usePropertyById = (propertyId?: string | null) => {
  const url = `/business-centers/${propertyId}`;
  return useSWR(!!propertyId ? url : null, () => getJsonAsync(url));
};



export const addEditProperty = async (payload: any) => {
  const url = `/business-centers/${payload.id}`;

  const result = await putJsonAsync(url, payload);
  return result;
};

export const deleteProperty = async (id: string) => {
  const url = `/business-centers/${id}`;

  const result = await deleteJsonAsync(url);
  return result;
};

///business-centers/{bc_id}/offices:

export const useOfficesByPropertyId = (propertyId?: string | null) => {
  const url = `/business-centers/${propertyId}/offices`;
  return useSWR(!!propertyId ? url : null, () => getJsonAsync(url));
};

export const addEditOfficeToProperty = async (propertyId: string, payload: any) => {
  const url = `/business-centers/${propertyId}/offices/${payload.id}`;
  const result = await putJsonAsync(url, payload);
  return result;
};

export const deleteOffice = async (propertyId: string, officeId: string) => {
  const url = `/business-centers/${propertyId}/offices/${officeId}`;

  const result = await deleteJsonAsync(url);
  return result;
};


export const uploadImage = async (
  bcId: string,
  fileName: string,
  payload: any,
  officeId?: string
) => {
  const headersForImg = getHeadersForImg();
  const commonPath = `/assets/business-centers/${bcId}/`;
  const path = !!officeId ? `${commonPath}offices/${officeId}/images/${fileName}` : `${commonPath}images/${fileName}`;
  const result = await putJsonAsync(path, payload, headersForImg);
  return result;
};

export const addImagesToBc = async (images: any, bcId: string, officeId?: string) => {
  const addImagePromises = images.map(
    async (image: any) => await uploadImage(bcId, image.name, image.blobData, officeId)
  );
  return await Promise.all(addImagePromises);
};

export const useImagesByPropertyId = (propertyId: string) => {
  const url = `/business-centers/${propertyId}/images`;
  return useSWR(url, () => getJsonAsync(url));
};

// /business-centers/{bc_id}/offices/{office_id}/images:

export const useImagesByPropertyIdAndOfficeId = (propertyId: string, officeId: string) => {
  const url = `/business-centers/${propertyId}/offices/${officeId}/images`;
  return useSWR(url, () => getJsonAsync(url));
};

export const deleteImage = async (
  bcId: string,
  fileName: string,
  officeId?: string
) => {
  const commonPath = `/business-centers/${bcId}/`;
  const url = !!officeId ? `${commonPath}offices/${officeId}/images/${fileName}` : `${commonPath}images/${fileName}`;
  const result = await deleteJsonAsync(url);
  return result;
};

//const responseWithoutToken = await getJsonAsync('/public-endpoint', true);